import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import '../styles/forms.scss';
import './Login.scss';
import { useAuth } from '../contexts/Auth';
import { useFormik } from 'formik';
import FieldInput from '../components/FieldInput';
import * as Yup from 'yup';

const Signup = () => {
  const history = useHistory();
  const { signup, googleLogin } = useAuth();
  const [didSubmit, setDidSubmit] = useState(false);

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email('Please enter a valid email.')
      .required('Please enter a valid email.'),
    password: Yup.string()
      .required('Please enter a valid password.')
      .matches(
        /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
        'Please enter a valid password.'
      ),
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    onSubmit: async ({ email, password }) => {
      try {
        await signup(email, password, () => history.push('/dashboard'));
      } catch (e) {
        switch (e.code) {
          case 'auth/email-already-in-use':
            formik.setFieldError('email', 'This email is already in use.');
            break;
          default:
            console.error(e);
        }
      }
    },
    validationSchema,
  });

  return (
    <div className="signup-page form-page">
      <form onSubmit={formik.handleSubmit} className="signup-form form">
        <h1 className="form-title">Sign up for yo/links</h1>
        <FieldInput
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.errors.email}
          name="email"
          type="email"
          placeholder="Email"
          didSubmit={didSubmit}
        />
        <FieldInput
          value={formik.values.password}
          onChange={formik.handleChange}
          error={formik.errors.password}
          placeholder="Password"
          name="password"
          type="password"
          didSubmit={didSubmit}
          note="Password must contain at least 8 characters, one uppercase, one number and one special case character."
        />
        <div className="form-actions">
          <button
            className="form-submit fill"
            type="submit"
            onClick={() => setDidSubmit(true)}
            data-testid="submit"
          >
            Sign up
          </button>
        </div>
        <div className="login-providers">
          <span className="line-header">Or</span>
          <button
            className="google"
            onClick={() => {
              googleLogin(() => history.push('/dashboard'));
            }}
          >
            <img src="/google-sign-in.png" alt="Sign In with Google" />
          </button>
        </div>
      </form>
      <Link className="signup-link" to="/login">
        Back to login
      </Link>
      <div className="privacy-links">
        By clicking "Sign up", you agree to our{' '}
        <Link to="/terms">Terms of Use</Link> and{' '}
        <Link to="/privacy">Privacy Policy</Link>.
      </div>
    </div>
  );
};

export default Signup;
