// DESCRIPTIONS
export const howToDesc = (
  <p>
    To use your yo/link, just navigate to{' '}
    <code>{'yolinks.io/<link-name>'}</code> and we'll send you on your way!
    <br />
    <br />
    <strong>Tip:</strong> You can use{' '}
    <strong className="blurple">yo/links</strong> even more easily by following
    the instructions below.
  </p>
);

export const shortcutDesc = (
  <p>
    Use our address bar shortcut to access your{' '}
    <strong className="blurple">yo/links</strong> more quickly without having to
    type out <code>yolinks.io/</code>.
  </p>
);

export const dynamicLinkDesc = (
  <>
    <p>
      Create a dynamic link to use one yo/link for different destinations with
      the same URL structure.
    </p>
    <p className="dynamic-segment-example">
      For example, a yo/link with its destination set to:
      <br />
      <code>
        www.reddit.com/r/<strong>{`{*}`}</strong>
      </code>
      <br />
      can be used to navigate to different parts of Reddit depending on what you
      type after the yo/link short name.
      <br />
    </p>
    <p>
      For example:
      <br />
      <code>
        www.reddit.com/r/<strong>AskReddit</strong>
      </code>
      <br />
      or
      <br />
      <code>
        www.reddit.com/r/<strong>aww</strong>
      </code>
    </p>
  </>
);

// STEPS
export const shortcutStepsFF = (
  <>
    <div className="carousel-step">
      <p>
        If you are using Firefox, right-click the address bar and select "Add
        YoLinks" in order to enable the address bar shortcut:
      </p>
      <img src="/opensearch-firefox.png" alt="OpenSearch Firefox" />
      <p className="note">
        <strong>Note:</strong> You only have to do this step{' '}
        <strong>once</strong>.
      </p>
    </div>
    <div className="carousel-step">
      <p>
        Start by typing <code>yolinks</code> into the address bar until you see{' '}
        <strong>&#x1F50D; Search YoLinks</strong> appear at the top of the
        suggestions list:
      </p>
      <img src="/opensearch.png" alt="OpenSearch prompt" />
    </div>
    <div className="carousel-step">
      <p>
        Press the <code>[Tab]</code> key to activate search mode. You will see{' '}
        <strong>Search YoLinks</strong> appear in the input box:
      </p>
      <img src="/opensearch-active.png" alt="OpenSearch active" />
    </div>
    <div className="carousel-step">
      <p>Type in your yo/link name:</p>
      <img src="/opensearch-term.png" alt="OpenSearch term" />
      <p>
        Then, hit <code>[Enter]</code> and you'll be taken to your destination!
      </p>
    </div>
  </>
);

export const shortcutSteps = (
  <>
    <div className="carousel-step">
      <p>
        Start by typing <code>yolinks</code> into the address bar until you see{' '}
        <strong>&#x1F50D; Search YoLinks</strong> appear at the top of the
        suggestions list:
      </p>
      <img src="/opensearch.png" alt="OpenSearch prompt" />
    </div>
    <div className="carousel-step">
      <p>
        Press the <code>[Tab]</code> key to activate search mode. You will see{' '}
        <strong>Search YoLinks</strong> appear in the input box:
      </p>
      <img src="/opensearch-active.png" alt="OpenSearch active" />
    </div>
    <div className="carousel-step">
      <p>Type in your yo/link name:</p>
      <img src="/opensearch-term.png" alt="OpenSearch term" />
      <p>
        Then, hit <code>[Enter]</code> and you'll be taken to your destination!
      </p>
    </div>
  </>
);

export const dynamicLinkSteps = (
  <>
    <div className="carousel-step">
      <p>
        To create a dynamic link, simply replace the variable part of the
        destination URL with the variable symbol{' '}
        <code>
          <strong>{'{*}'}</strong>
        </code>
        .
      </p>
      <img
        className="dynamic-link-example"
        alt="Dynamic link example"
        src="/dynamic-example.png"
      />
    </div>
    <div className="carousel-step">
      <p>
        Now when you want to visit a specific part of Reddit, you can use your
        link short name followed by a forward slash <code>{'/'}</code> and the
        variable part of your destination to tell{' '}
        <strong className="blurple">yo/links</strong> where to take you.
      </p>
      <img src="/dynamic-example-search.png" alt="Dynamic link search" />
      <p className="note">
        This would navigate you to
        <br />
        <strong>https://reddit.com/r/aww</strong>
      </p>
    </div>
  </>
);
