import React, { useRef } from 'react';
import ReactTooltip from 'react-tooltip';
import { AiFillDelete } from 'react-icons/ai';
import './ConfirmDelete.scss';

const ConfirmDelete = ({ link, deleteLink }) => {
  // Need ref for Cancel button to work: https://github.com/wwayne/react-tooltip/issues/449
  const tooltip = useRef(null);

  return (
    <>
      <button
        type="button"
        data-tip={JSON.stringify({ link })}
        data-event="click"
        className="link-actions--delete"
      >
        <AiFillDelete size={24} />
      </button>
      <ReactTooltip
        ref={tooltip}
        globalEventOff="click"
        effect="solid"
        place={'bottom'}
        className="popup-content"
        getContent={(dataStr) => {
          // data-tip is passed as string, so we must parse it: https://www.npmjs.com/package/react-tooltip
          let link = {};
          if (dataStr) {
            link = JSON.parse(dataStr).link;
          }

          return (
            <div>
              <div className="popup-content--message">
                Are you sure you want to permanently delete{' '}
                <strong>yo/{link.name}</strong>?
              </div>
              <div className="popup-content--buttons">
                <button
                  onClick={() => deleteLink(link)}
                  className="popup-content--delete"
                >
                  Yes, delete it!
                </button>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    // https://github.com/wwayne/react-tooltip/issues/449
                    const current = tooltip.current;
                    current.tooltipRef = null;
                    ReactTooltip.hide();
                  }}
                  className="popup-content--cancel"
                >
                  Cancel
                </button>
              </div>
            </div>
          );
        }}
        clickable
      />
    </>
  );
};

export default ConfirmDelete;
