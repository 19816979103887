import { useEffect, useState } from 'react';
import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import Signup from './pages/Signup';
import Login from './pages/Login';
import Home from './pages/Home';
import PrivacyPage from './pages/legal/Privacy';
import { AuthProvider, useAuth } from './contexts/Auth';
import { LinksProvider } from './contexts/Links';
import RedirectPage from './pages/RedirectPage';
import { CookiesProvider } from 'react-cookie';
import { TourProvider } from './contexts/Tour';
import PasswordReset from './pages/PasswordReset';
import TermsPage from './pages/legal/Terms';
import Landing from './pages/Landing';

const LandingRoute = () => {
  const auth = useAuth();

  if (auth.user) {
    return <Redirect to={{ pathname: '/dashboard' }} />;
  } else {
    return <Landing />;
  }
};

const PrivateRoute = ({ children, ...options }) => {
  const auth = useAuth();

  return (
    <Route
      {...options}
      render={({ location }) => {
        if (auth.user) {
          return children;
        } else {
          return (
            <Redirect to={{ pathname: '/login', state: { from: location } }} />
          );
        }
      }}
    />
  );
};

function AppContainer() {
  return (
    <CookiesProvider>
      <AuthProvider>
        <TourProvider>
          <LinksProvider>
            <Router>
              <App />
            </Router>
          </LinksProvider>
        </TourProvider>
      </AuthProvider>
    </CookiesProvider>
  );
}

function App() {
  const auth = useAuth();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (auth.user !== null) {
      setIsLoading(false);
    }
  }, [auth.user]);

  if (isLoading) {
    return null;
  }

  return (
    <div className="app">
      <Switch>
        <Route path="/signup">
          <Signup />
        </Route>
        <Route path="/login">
          <Login />
        </Route>
        <Route path="/reset-password">
          <PasswordReset />
        </Route>
        <Route path="/privacy">
          <PrivacyPage />
        </Route>
        <Route path="/terms">
          <TermsPage />
        </Route>
        <Route path="/" exact>
          <LandingRoute />
        </Route>
        <PrivateRoute path="/dashboard">
          <Home />
        </PrivateRoute>
        <PrivateRoute path="/">
          <RedirectPage />
        </PrivateRoute>
      </Switch>
    </div>
  );
}

export default AppContainer;
