import React from 'react';
import '../styles/forms.scss';

const FieldNote = ({ note }) => {
  return <div className="field-input-note">{note}</div>;
};

const FieldError = ({ message }) => {
  return <div className="field-input-error">{message}</div>;
};

/**
 * Wrapper component for form field inputs that handles rendering labels and
 * errors.
 *
 * Usage:
 * <form>
 *   <FieldInput
 *     value={value}
 *     onChange={onChange}
 *     name={name}
 *     type={type}
 *     placeholder={placeholder}
 *     error={error}
 *     didSubmit={didSubmit}
 *     note={note}
 *     label={label}
 *   />
 * </form>
 */
const FieldInput = ({
  value,
  onChange,
  name,
  type,
  placeholder,
  error,
  didSubmit,
  note,
  label,
  wrapperClass,
  inputClass,
  autoFocus,
  contentLeft,
}) => {
  return (
    <div className={`field-input-wrapper ${wrapperClass && wrapperClass}`}>
      {label ? <label htmlFor={name}>{label}</label> : null}
      <div className="field-input--input-wrapper">
        <div className="field-input-content-left">{contentLeft}</div>
        <input
          value={value}
          onChange={onChange}
          name={name}
          type={type}
          placeholder={placeholder}
          data-testid={`${name}-input`}
          className={`${inputClass && inputClass} ${
            error && didSubmit ? 'error' : ''
          } `}
          autoFocus={autoFocus}
        />
      </div>
      {error && didSubmit ? <FieldError message={error} /> : null}
      {note ? <FieldNote note={note} /> : null}
    </div>
  );
};

export default FieldInput;
