export function formatUrl(url, pathname, key) {
  let formattedUrl = url;

  // Get all segments in path following the key substring
  const segments = pathname.substr(key.length).split('/').slice(1);

  // If any wildcard characters exist, use segments to populate them
  while (formattedUrl.includes('{*}')) {
    formattedUrl = formattedUrl.replace('{*}', segments.shift());
  }

  if (segments.length)
    // Insert slash if no trailing slash already exists
    // Any remaining segments are appended as additional URI parts
    formattedUrl = `${formattedUrl}${
      formattedUrl.endsWith('/') ? '' : '/'
    }${segments.join('/')}`;

  return formattedUrl;
}

export function redirectToExternal(urls) {
  urls.forEach((url, index) => {
    if (index === 0) {
      return;
    }
    const win = window.open(url, '_blank');
    if (!win) {
      throw new Error('Popup blocker detected');
    }
  });
  window.location.replace(urls[0]);
}
