import React, { useEffect } from 'react';
import { analytics } from '../api/Firebase';
import LinksTable from '../components/LinksTable';
import TipsTable from '../components/TipsTable';
import { useAuth } from '../contexts/Auth';
import './Home.scss';

const Home = () => {
  const { user, logout } = useAuth();

  useEffect(() => {
    analytics.logEvent('page_view', { page_title: 'Dashboard', uid: user.uid });
  }, [user]);

  return (
    <div className="home-page">
      {/* Hidden element to display first tour step */}
      <div className="tour-step-1" />
      {/* todo: CTA to download extension */}
      <div className="home-header">
        <h1 className="logo">yo/links</h1>
        <button className="logout" onClick={logout}>
          Log out
        </button>
      </div>
      <div className="home-content">
        <LinksTable />
        <TipsTable />
      </div>
    </div>
  );
};

export default Home;
