import React from 'react';
import { Link } from 'react-router-dom';
import './Landing.scss';

const Landing = () => {
  return (
    <div className="landing-page">
      <header>
        <div className="landing-header">
          <h1 className="logo">yo/links</h1>
          <div className="user-actions">
            <Link to="/login" className="login">
              Log in
            </Link>
            <Link to="/signup" className="sign-up">
              Sign up
            </Link>
          </div>
        </div>
      </header>
      <main className="landing-content">
        <section className="hero">
          <div className="hero-copy one">
            <h1 className="hero-title">
              Short, named links for all your URLs.
            </h1>
            <p className="hero-body">
              <strong>
                <span className="blurple">yo/links</span>
              </strong>{' '}
              are memorable short links that you define. Forget about complex
              URLs and navigate the web in a way that makes sense.
            </p>
            <Link to="/signup" className="primary-cta">
              Try it out. It's free.
            </Link>
          </div>
          <div className="hero-image two">
            <img src="/landing-hero.png" alt="yo/links illustration" />
          </div>
        </section>
        <section className="how-it-works" id="how-it-works">
          <h1>How it works</h1>
          <ol className="how-it-works-list">
            <li className="how-it-works--label">
              <p>
                Create a{' '}
                <strong>
                  <span className="blurple">yo/link</span>
                </strong>{' '}
                with a short name and a destination.
              </p>
            </li>
            <li className="how-it-works--img">
              <img src="/landing-example.png" alt="Example yo/link" />
            </li>
            <li className="how-it-works--label">
              <p>
                Type the name into your address bar and you'll be taken to the
                destination!
              </p>
            </li>
            <li className="how-it-works--img">
              <img src="/landing-gif.gif" alt="Gif of yo/link navigation" />
            </li>
          </ol>
        </section>
        <section>
          <div className="last">
            <h1>Ready to make your life easier?</h1>
            <Link to="/signup" className="primary-cta">
              Try it out. It's free.
            </Link>
          </div>
        </section>
      </main>
      <footer>
        <div className="landing-footer">
          <h1 className="logo">yo/links</h1>
          <div className="legal-links">
            <a href="mailto:yolinks.app@gmail.com">Contact Us</a>
            <Link to="/privacy">Privacy Policy</Link>
            <Link to="/terms">Terms of Service</Link>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Landing;
