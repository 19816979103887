import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { useLinks } from '../contexts/Links';
import { redirectToExternal, formatUrl } from '../utils/redirect';

import './Home.scss';

const AllowPopupsInstructions = () => {
  return (
    <div className="allow-popups-message">
      <h2>
        <mark className="brick">Whoops!</mark> You need to allow popups on our
        site to use multilink redirects.
      </h2>
      <img src="/allowpopups.png" alt="Allow popups" />
      <h3>Follow these steps to enable redirects:</h3>
      <p>
        1. Click on the <strong>pop-ups blocked icon</strong>{' '}
        <img src="/popupblocked.png" alt="Pop-ups blocked icon" width={32} /> in
        your address bar.
      </p>
      <p>
        2. Select{' '}
        <strong>
          Always allow pop-ups and redirects from https://yolinks.io.
        </strong>
      </p>
      <p>
        3. Click <strong>Done</strong> and refresh the page!
      </p>
    </div>
  );
};

const RedirectMessage = ({ url }) => {
  return url ? `Redirecting you to ${url}` : 'Fetching redirect destination...';
};

const RedirectPage = () => {
  const { links } = useLinks();
  const [redirectUrl, setRedirectUrl] = useState('');
  const [isRedirecting, setIsRedirecting] = useState(false);
  const [showPopupError, setShowPopupError] = useState(false);
  const location = useLocation();

  useEffect(() => {
    // This component double renders when `links` changes for some reason,
    // which causes the destination URLs to open twice. I couldn't figure
    // out why, so we use a flag to stop the second render from doing
    // anything for now.
    if (isRedirecting) {
      return;
    }

    // Don't do anything if links aren't loaded yet
    if (!links.length) {
      return;
    }

    setIsRedirecting(true);
    // GET requests from OpenSearch use percent encoding
    const pathname = decodeURIComponent(location.pathname.substr(1));
    const link = links
      .filter((l) => pathname.startsWith(l.name))
      // Initial value is '' in case of empty array
      .reduce((a, b) => (a.length > b.length ? a : b), '');
    if (link) {
      const urls = link.urls.map((url) => formatUrl(url, pathname, link.name));
      setRedirectUrl(urls.toString());
      try {
        redirectToExternal(urls);
      } catch (e) {
        if (e.message === 'Popup blocker detected') {
          setShowPopupError(true);
        }
      }
    } else {
      setIsRedirecting(false);
      window.location.replace('/dashboard');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [links]);

  return (
    <div className="home-page">
      <div className="home-header">
        <h1 className="logo">yo/links</h1>
      </div>
      {showPopupError ? (
        <AllowPopupsInstructions />
      ) : (
        <RedirectMessage url={redirectUrl} />
      )}
    </div>
  );
};

export default RedirectPage;
