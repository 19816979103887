import { TourProvider as ReactourProvider } from '@reactour/tour';
import '../styles/tour.scss';
import { useAuth } from './Auth';
import { analytics } from '../api/Firebase';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

const tourSteps = [
  {
    selector: '.tour-step-1',
    content: (
      <p>
        Welcome to <strong className="blurple">yo/links</strong>! We make it
        easy to access and manage your frequently used web pages.
      </p>
    ),
    position: 'center',
  },
  {
    selector: '.link-container:not(.link-container--editing)',
    content: (
      <p>
        This is a <strong className="blurple">yo/link</strong>. Every{' '}
        <strong className="blurple">yo/link</strong> has a <strong>name</strong>{' '}
        and a <strong>destination</strong>. Try visiting{' '}
        <code>yolinks.io/gatsby</code> to see it in action!
      </p>
    ),
    position: 'right',
  },
  {
    selector: '.link-container--editing',
    content: (
      <p>
        Let's create your first custom{' '}
        <strong className="blurple">yo/link</strong>.
      </p>
    ),
  },
  {
    selector: '.link-name--editable',
    content: (
      <p>
        Enter a short name for a web page that you visit frequently. For
        example, if you have a to-do list, you could name your link "todo".
      </p>
    ),
  },
  {
    selector: '.link-list--editable .link-actions.row input[type="text"]',
    content: (
      <p>
        Enter the URL that you want this{' '}
        <strong className="blurple">yo/link</strong> to point to.
      </p>
    ),
  },
  {
    selector: '.link-edit--save',
    content: (
      <p>
        Finally, hit <strong>Add yo/link</strong>. You're done! To use this
        yo/link, simply navigate to <code>yolinks.io/{'<short-name>'}</code>.
      </p>
    ),
  },
];

export const TourProvider = ({ children }) => {
  const { user } = useAuth();

  const completeTour = () => {
    analytics.logEvent('tour_complete', { uid: user.uid });
    enableBodyScroll(document);
  };

  return (
    <ReactourProvider
      steps={tourSteps}
      disableFocusLock
      className="tour-popover"
      styles={{
        popover: (base) => ({
          ...base,
          borderRadius: '8px',
        }),
      }}
      nextButton={(base) => {
        if (base.currentStep === base.stepsLength - 1) {
          return <div style={{ width: 16 }} />;
        }
        return <base.Button />;
      }}
      beforeClose={completeTour}
      afterOpen={() => disableBodyScroll(document)}
      showBadge={false}
      showCloseButton={true}
    >
      {children}
    </ReactourProvider>
  );
};
