import React from 'react';
import Tip from '../components/Tip';
import is from 'is_js';
import './TipsTable.scss';
import {
  dynamicLinkDesc,
  dynamicLinkSteps,
  shortcutDesc,
  shortcutSteps,
  shortcutStepsFF,
  howToDesc,
} from '../utils/tips';

const TipsTable = () => {
  const tips = [
    {
      title: 'How to use your yo/link',
      description: howToDesc,
      steps: null,
    },
    {
      title: 'Yo/links Shortcut',
      description: shortcutDesc,
      steps: is.firefox() ? shortcutStepsFF : shortcutSteps,
    },
    {
      title: 'Dynamic Links',
      description: dynamicLinkDesc,
      steps: dynamicLinkSteps,
    },
  ];

  return (
    <div className="tips-table">
      <h5 className="section-heading">Tips & Tricks</h5>
      {tips.map((tip) => (
        <Tip content={tip} key={tip.title} />
      ))}
    </div>
  );
};

export default TipsTable;
