import React, { useCallback, useState } from 'react';
import { AiOutlinePlus, AiOutlineClose } from 'react-icons/ai';
import FieldInput from './FieldInput';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useLinks } from '../contexts/Links';
import './EditableLink.scss';

const getValidUrl = (url = '') => {
  // https://stackoverflow.com/questions/11300906/check-if-a-string-starts-with-http-using-javascript
  let newUrl = window.decodeURIComponent(url);
  newUrl = newUrl.trim().replace(/\s/g, '');

  if (/^(:\/\/)/.test(newUrl)) {
    return `https${newUrl}`;
  }
  if (!/^(file|ftp|http|https):\/\//i.test(newUrl)) {
    return `https://${newUrl}`;
  }

  return newUrl;
};

const EditableLink = ({ link, stopEditing, isNew = false, onSubmit }) => {
  const { reserved, links, updateLinks } = useLinks();
  const [didSubmit, setDidSubmit] = useState(false);

  const update = useCallback(
    async (link, newDest, isNew) => {
      const updatedLinks = [...links];
      const newLink = {
        ...link,
        urls: newDest,
        updatedAt: new Date(),
      };
      // Remove index prop before writing new link
      delete newLink.index;
      if (isNew) {
        newLink['createdAt'] = new Date();
        updatedLinks.push(newLink);
      } else {
        updatedLinks[link.index] = newLink;
      }
      updateLinks(updatedLinks);
    },
    [links, updateLinks]
  );

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required('Please enter a name.')
      .matches(
        RegExp(String.raw`^(?!${reserved.join('$|')}$).*`),
        'Sorry, that name is reserved for a yo/links page.'
      )
      .matches(
        RegExp(
          String.raw`^(?!${links
            .map((l) => l.name)
            .filter((linkName) => (isNew ? true : linkName !== link.name))
            .join('$|')}$).*`
        ),
        'You already have a YoLink with that name!'
      ),
  });

  const formik = useFormik({
    initialValues: {
      name: isNew ? '' : link.name,
      urls: isNew ? [''] : link.urls,
    },
    onSubmit: async ({ name, urls }) => {
      await update(
        { ...link, name },
        urls.filter((url) => url).map(getValidUrl),
        isNew
      );
      formik.resetForm();
      setDidSubmit(false);
      if (stopEditing) stopEditing();
      if (onSubmit) onSubmit();
    },
    validationSchema,
  });

  const cancel = () => {
    setDidSubmit(false);
    formik.resetForm();
    stopEditing();
  };

  const addDest = (e) => {
    e.preventDefault();
    formik.setFieldValue('urls', [...formik.values.urls, '']);
  };

  return (
    <form
      onSubmit={formik.handleSubmit}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          e.preventDefault();
          formik.handleSubmit();
        }
      }}
    >
      <div className="link-name">
        <FieldInput
          value={formik.values.name}
          onChange={formik.handleChange}
          error={formik.errors.name}
          name="name"
          type="text"
          placeholder="Enter a name"
          didSubmit={didSubmit}
          contentLeft={<span className="link-yo">yo/</span>}
          wrapperClass="link-name-wrapper--editable"
          inputClass="link-name--editable"
          autoFocus={!isNew}
        />
      </div>
      <div className="link-list--editable">
        {formik.values.urls.map((url, index) => (
          <li key={index} className="link-actions row">
            <input
              type="text"
              placeholder="e.g https://yolinks.io/"
              value={url}
              onChange={(e) => {
                const newUrls = [...formik.values.urls];
                newUrls[index] = e.target.value;
                formik.setFieldValue('urls', newUrls);
              }}
            />
            <button
              className="link-actions--delete"
              onClick={(e) => {
                e.preventDefault();
                const newUrls = [...formik.values.urls];
                newUrls.splice(index, 1);
                formik.setFieldValue('urls', newUrls);
              }}
            >
              <AiOutlineClose size={20} />
            </button>
          </li>
        ))}
        <button onClick={addDest} className="link-actions--add">
          <AiOutlinePlus size={14} style={{ marginRight: 4 }} />
          Add Destination
        </button>
        <div className="link-buttons">
          <button
            type="submit"
            className="link-edit--save"
            onClick={() => setDidSubmit(true)}
          >
            {isNew ? 'Add yo/link' : 'Save'}
          </button>
          {!isNew && (
            <button
              type="button"
              onClick={cancel}
              className="link-edit--cancel"
            >
              Cancel
            </button>
          )}
        </div>
      </div>
    </form>
  );
};

export default EditableLink;
