import React, { useEffect, useState, useCallback } from 'react';
import { useLinks } from '../contexts/Links';
import YoLink from './YoLink';
import EditableLink from './EditableLink';
import './LinksTable.scss';
import { useTour } from '@reactour/tour';
import { useAuth } from '../contexts/Auth';
import { SORT_METHODS } from '../utils/sort';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import is from 'is_js';

const LinksTable = () => {
  const { isNewUser } = useAuth();
  const { links, updateLinks, isLoading } = useLinks();
  const [sortBy, setSortBy] = useState('newest');
  const [sortedLinks, setSortedLinks] = useState([]);
  const { setIsOpen, setCurrentStep } = useTour();

  useEffect(() => {
    if (isNewUser && !isLoading && !is.mobile()) {
      setCurrentStep(0);
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNewUser, isLoading]);

  useEffect(() => {
    const sorted = [...links]
      .map((link, index) => ({ ...link, index }))
      .sort(SORT_METHODS[sortBy]);
    setSortedLinks(sorted);
  }, [links, sortBy]);

  const deleteLink = useCallback(
    async (link) => {
      const updatedLinks = [...links];
      updatedLinks.splice(link.index, 1);
      updateLinks(updatedLinks);
    },
    [links, updateLinks]
  );

  return (
    <div className="links-table">
      <h5 className="section-heading">Links</h5>
      <div className="link-container link-container--editing">
        <EditableLink isNew onSubmit={() => setIsOpen(false)} />
      </div>
      {isLoading && (
        <div className="skelly-container">
          <SkeletonTheme color="#edf1f7" highlightColor="#f3f6fc">
            <Skeleton className="skelly" height={100} count={4} />
          </SkeletonTheme>
        </div>
      )}
      {sortedLinks.map((link) => (
        <YoLink link={link} key={link.index} deleteLink={deleteLink} />
      ))}
    </div>
  );
};

export default LinksTable;
