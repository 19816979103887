import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { useAuth } from '../contexts/Auth';
import './Login.scss';
import '../styles/forms.scss';
import FieldInput from '../components/FieldInput';

const Login = () => {
  const { emailLogin, googleLogin } = useAuth();
  const history = useHistory();
  const [didSubmit, setDidSubmit] = useState(false);

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email('Please enter a valid email.')
      .required('Please enter a valid email.'),
    password: Yup.string().required('Please enter a valid password.'),
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    onSubmit: async ({ email, password }) => {
      try {
        await emailLogin(email, password, () => history.push('/dashboard'));
      } catch (e) {
        switch (e.code) {
          case 'auth/wrong-password':
            formik.setFieldError(
              'password',
              'Incorrect password. Please check your password and try again.'
            );
            break;
          case 'auth/user-not-found':
            formik.setFieldError(
              'email',
              "We couldn't find a user with this email address."
            );
            break;
          default:
            console.error(e);
        }
      }
    },
    validationSchema,
  });

  return (
    <div className="login-page form-page">
      <form onSubmit={formik.handleSubmit} className="login-form form">
        <h1 className="form-title">Log in to yo/links</h1>
        <FieldInput
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.errors.email}
          name="email"
          type="email"
          placeholder="Email"
          didSubmit={didSubmit}
        />
        <FieldInput
          value={formik.values.password}
          onChange={formik.handleChange}
          error={formik.errors.password}
          placeholder="Password"
          name="password"
          type="password"
          didSubmit={didSubmit}
        />
        <div className="form-actions">
          <button
            className="form-submit fill"
            type="submit"
            onClick={() => setDidSubmit(true)}
            data-testid="submit"
          >
            Log in
          </button>
          <Link className="password-reset-link" to="/reset-password">
            Forgot password?
          </Link>
        </div>
        <div className="login-providers">
          <span className="line-header">Or</span>
          <button
            className="google"
            onClick={() => {
              googleLogin(() => history.push('/dashboard'));
            }}
          >
            <img src="/google-sign-in.png" alt="Sign In with Google" />
          </button>
        </div>
      </form>
      <Link className="signup-link" to="/signup">
        No account yet? Sign up
      </Link>
      <div className="privacy-links">
        By clicking "Log in", you agree to our{' '}
        <Link to="/terms">Terms of Use</Link> and{' '}
        <Link to="/privacy">Privacy Policy</Link>.
      </div>
    </div>
  );
};

export default Login;
