/*global chrome*/
export const signInToExtension = (session) => {
  const isChrome =
    !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
  if (isChrome) {
    chrome.runtime.sendMessage(
      process.env.REACT_APP_CHROME_EXTENSION_ID,
      {
        session,
      },
      function (response) {
        console.log('sign in response:', response);
      }
    );
  }
};
