export const SORT_METHODS = {
  newest: (a, b) => toDate(b.createdAt) - toDate(a.createdAt),
  oldest: (a, b) => toDate(a.createdAt) - toDate(b.createdAt),
  abc: (a, b) => a.name.localeCompare(b.name),
};

export const toDate = (date) => {
  // If `links` was updated locally, we already have Date objects
  if (date instanceof Date) {
    return date;
  }
  // If `links` was freshly fetched from Firebase, we have FB Timestamp objects
  return date.toDate();
};
