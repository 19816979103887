import React, { useContext, useEffect, useState } from 'react';
import { useAuth } from './Auth';
import { analytics, db } from '../api/Firebase';

/**
 * Provides context and hook for all auth concerns.
 * https://usehooks.com/useAuth/
 */
export const LinksContext = React.createContext();

export const useLinks = () => {
  return useContext(LinksContext);
};

const useLinksProvider = () => {
  const [links, setLinks] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [reserved, setReserved] = useState([]);
  const auth = useAuth();

  // Fetch links when user updates
  useEffect(() => {
    (async () => {
      await fetchLinks();
      await fetchReservedNames();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.user]);

  const fetchReservedNames = async () => {
    const doc = await db.collection('users').doc('public').get();
    const links = doc.data().links;
    const names = links.map((link) => link.name);
    setReserved(names);
    return names;
  };

  const fetchLinks = async () => {
    if (auth.user) {
      const doc = await db.collection('users').doc(auth.user.uid).get();
      let data = [];
      if (doc.data()) {
        data = doc.data().links;
      }
      setLinks(data);
      setIsLoading(false);
      return data;
    }
  };

  const updateLinks = async (newLinks) => {
    const res = await db.collection('users').doc(auth.user.uid).set(
      {
        links: newLinks,
      },
      {
        merge: true,
      }
    );
    setLinks(newLinks);
    analytics.logEvent('links_update', { uid: auth.user.uid });
    return res;
  };

  return {
    links,
    reserved,
    setLinks,
    fetchLinks,
    updateLinks,
    isLoading,
  };
};

export const LinksProvider = ({ children }) => {
  const linksProvider = useLinksProvider();
  return (
    <LinksContext.Provider value={linksProvider}>
      {children}
    </LinksContext.Provider>
  );
};
