import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import { BsFillCaretLeftFill, BsFillCaretRightFill } from 'react-icons/bs';

import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './Tip.scss';

const TipCarousel = ({ children }) => (
  <Carousel
    showStatus={false}
    showThumbs={false}
    infiniteLoop={true}
    renderArrowPrev={(clickHandler) => (
      <button onClick={clickHandler} className="step-button step-button--prev">
        <BsFillCaretLeftFill />
      </button>
    )}
    renderArrowNext={(clickHandler) => (
      <button onClick={clickHandler} className="step-button step-button--next">
        <BsFillCaretRightFill />
      </button>
    )}
    renderIndicator={(clickHandler, isSelected) => (
      <button
        onClick={clickHandler}
        className={`step-indicator ${isSelected && 'active'}`}
      />
    )}
  >
    {children}
  </Carousel>
);

const Tip = ({ content }) => {
  const steps = content.steps?.props.children;
  return (
    <div className="tip">
      <h5>{content.title}</h5>
      {content.description}
      <TipCarousel>
        {steps?.map((value, index) => {
          return (
            <div key={index}>
              <h6>
                Step {index + 1}/{steps.length}
              </h6>
              {value}
            </div>
          );
        })}
      </TipCarousel>
    </div>
  );
};

export default Tip;
