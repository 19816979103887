import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import { useAuth } from '../contexts/Auth';
import './Login.scss';
import '../styles/forms.scss';
import FieldInput from '../components/FieldInput';

const PasswordReset = () => {
  const auth = useAuth();
  const [didSubmit, setDidSubmit] = useState(false);
  const [success, setSuccess] = useState(false);

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email('Please enter a valid email.')
      .required('Please enter a valid email.'),
  });

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    onSubmit: async ({ email }) => {
      try {
        await auth.sendPasswordResetEmail(email);
        setSuccess(true);
      } catch (e) {
        if (e.code === 'auth/user-not-found') {
          formik.setFieldError('email', 'No user found with that email');
        }
      }
    },
    validationSchema,
  });

  const SuccessMessage = () => (
    <p>
      An email with further instructions has been sent to{' '}
      <strong>{formik.values.email}</strong>.
    </p>
  );

  return (
    <div className="password-reset-page form-page">
      <form onSubmit={formik.handleSubmit} className="password-reset-form form">
        <h2 className="form-title left">Reset your password</h2>
        {success ? (
          <SuccessMessage />
        ) : (
          <>
            <p>
              Don't worry, it happens to the best of us. Enter the email address
              associated with your yo/links account to receive a reset link.
            </p>
            <FieldInput
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.errors.email}
              name="email"
              type="email"
              placeholder="Email"
              didSubmit={didSubmit}
            />
            <div className="form-actions">
              <button
                className="form-submit"
                type="submit"
                onClick={() => setDidSubmit(true)}
                data-testid="submit"
              >
                Reset password
              </button>
            </div>
          </>
        )}
      </form>
      <Link className="signup-link" to="/login">
        Back to login
      </Link>
    </div>
  );
};

export default PasswordReset;
