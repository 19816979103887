import React, { useState } from 'react';
import { AiFillEdit } from 'react-icons/ai';
import EditableLink from './EditableLink';
import ConfirmDelete from './ConfirmDelete';

import './YoLink.scss';

const UrlList = ({ link }) => {
  return (
    <ul className="link-list">
      {link.urls.map((dest, index) => (
        <li key={index} className="link-list--entry">
          {/* eslint-disable-next-line react/jsx-no-target-blank */}
          <a href={dest} target="_blank">
            {dest}
          </a>
        </li>
      ))}
    </ul>
  );
};

const YoLink = ({ link, deleteLink }) => {
  const [isEditing, setIsEditing] = useState(false);

  const CurrentLink = () => (
    <>
      <div className="link-header">
        <div className="link-name">
          <span className="link-yo">yo/</span>
          {link.name}
        </div>
        <div className="link-actions">
          <button
            className="link-actions--edit"
            onClick={() => setIsEditing(!isEditing)}
          >
            <AiFillEdit size={24} />
          </button>
          <ConfirmDelete deleteLink={deleteLink} link={link} />
        </div>
      </div>
      <UrlList link={link} />
    </>
  );

  return (
    <div className={`link-container ${isEditing && 'link-container--editing'}`}>
      {isEditing ? (
        <EditableLink link={link} stopEditing={() => setIsEditing(false)} />
      ) : (
        <CurrentLink />
      )}
    </div>
  );
};

export default YoLink;
