import React, { useContext, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { signInToExtension } from '../api/Chrome';
import firebase, { analytics } from '../api/Firebase';

/**
 * Provides context and hook for all auth concerns.
 * https://usehooks.com/useAuth/
 */
export const AuthContext = React.createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

const useAuthProvider = () => {
  const [user, setUser] = useState(null);
  const [isNewUser, setIsNewUser] = useState(false);
  const [cookies, setCookie, removeCookie] = useCookies([
    'session',
    'uid',
    'idToken',
    'refreshToken',
  ]);

  const initializeUser = async (data) => {
    return firebase
      .firestore()
      .collection('users')
      .doc(data.user.uid)
      .set({
        links: [
          {
            name: 'gatsby',
            urls: [
              'https://www.gutenberg.org/cache/epub/64317/pg64317-images.html',
            ],
            createdAt: new Date(2),
            updatedAt: firebase.firestore.Timestamp.now(),
          },
          {
            name: 'r',
            urls: ['https://reddit.com/r/{*}'],
            createdAt: new Date(1),
            updatedAt: firebase.firestore.Timestamp.now(),
          },
          {
            name: 'bp',
            urls: [
              'https://www.instagram.com/jennierubyjane/',
              'https://www.instagram.com/lalalalisa_m/',
              'https://www.instagram.com/roses_are_rosie/',
              'https://www.instagram.com/sooyaaa__',
            ],
            createdAt: new Date(0),
            updatedAt: firebase.firestore.Timestamp.now(),
          },
        ],
        createdAt: firebase.firestore.Timestamp.now(),
        updatedAt: firebase.firestore.Timestamp.now(),
      });
  };

  const signup = async (email, password, callback) => {
    const res = await firebase
      .auth()
      .createUserWithEmailAndPassword(email, password);
    if (res) {
      login(res);
      callback();
    }
  };

  const googleLogin = async (callback) => {
    try {
      const provider = new firebase.auth.GoogleAuthProvider();
      const res = await firebase.auth().signInWithPopup(provider);
      if (res) {
        login(res);
        callback();
      }
    } catch (e) {
      if (e.code === 'auth/popup-closed-by-user') {
        console.warn(e);
      } else {
        console.error(e);
      }
    }
  };

  const emailLogin = async (email, password, callback) => {
    const res = await firebase
      .auth()
      .signInWithEmailAndPassword(email, password);
    if (res) {
      login(res);
      callback();
    }
  };

  const login = (userCredential) => {
    let isNew = userCredential.additionalUserInfo.isNewUser;
    setIsNewUser(isNew);
    if (isNew) {
      initializeUser(userCredential);
    }

    setUser(userCredential.user);

    analytics.logEvent(isNewUser ? 'sign_up' : 'login', {
      uid: userCredential.user.uid,
      providerId: userCredential.additionalUserInfo.providerId, // emailLogin => "password", googleLogin => "google.com"
    });
  };

  const logout = async () => {
    analytics.logEvent('logout', { uid: user.uid });
    await firebase.auth().signOut();
  };

  const sendPasswordResetEmail = async (email) => {
    await firebase.auth().sendPasswordResetEmail(email);
  };

  useEffect(() => {
    if (cookies.session) {
      setUser(cookies.session);
    }

    const unsubscribe = firebase.auth().onAuthStateChanged(async (_user) => {
      if (_user) {
        analytics.setUserId(_user.uid);
        setUser(_user);
        const session = {
          idToken: await _user.getIdToken(),
          uid: _user.uid,
          refreshToken: _user.refreshToken,
        };
        setCookie('session', session, {
          path: '/',
          sameSite: 'none',
          secure: true,
        });
        setCookie('idToken', session.idToken, {
          path: '/',
          sameSite: 'none',
          secure: true,
        });
        setCookie('uid', session.uid, {
          path: '/',
          sameSite: 'none',
          secure: true,
        });
        setCookie('refreshToken', session.refreshToken, {
          path: '/',
          sameSite: 'none',
          secure: true,
        });
        signInToExtension(session);
      } else {
        setUser(false);
        removeCookie('session');
        removeCookie('idToken');
        removeCookie('uid');
        removeCookie('refreshToken');
      }
    });
    return unsubscribe;
  }, []);

  return {
    user,
    setUser,
    isNewUser,
    signup,
    emailLogin,
    googleLogin,
    logout,
    sendPasswordResetEmail,
  };
};

export const AuthProvider = ({ children }) => {
  const auth = useAuthProvider();
  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};
